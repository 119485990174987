/* eslint-disable max-len */
import {
  TagAll,
  TagCareer,
  TagLifestyle,
  TagLove,
  TagMovie,
  TagMusic,
  TagPhilosophy,
  TagRelationship,
  TagWriting,
  TagEvent,
} from 'components/Icons/TagIcons';
import { RoleType } from 'types/interface';
import { Colors } from './Colors';

export enum AfterLoginActionKeys {
  OPEN_NOTIFICATION = 'open-notification-action',
  WAITLIST = 'waitlist-action',
  REOPENING = 'reopening-action',
  BOOKMARK = 'bookmark-action',
  FOLLOW = 'follow-action',
}

export enum CookieKeys {
  USER = 'nfyg-user',
  ACCESS_TOKEN = 'nfyg-at',
  REFRESH_TOKEN = 'nfyg-rt',
  FIREBASE_ID_TOKEN = 'nfyg-fb-id-t',
  PROVIDER_TOKEN = 'nfyg-provide-token',
  PROVIDER = 'nfyg-provider',
  USER_PROFILE_TEMP = 'nfyg-u-p-temp',
  TEMP_REGISTER = 'nfyg-temp-at',
  USER_RECENT_SEARCH = 'nfyg-user-recent-search',
}

export enum UserMarketingPreferenceKeys {
  BOOKMARK_TO_OPEN = 'bookmark-to-open-notification-checked',
  BOOKMARK_TO_WAITING = 'bookmark-to-waitlist-checked',
  BOOKMARK_TO_REQUEST = 'bookmark-to-reopening-request-checked',
}

export enum ToolTipKeys {
  PRE_CTA_TOOLTIP = 'pre-cta-tooltip',
  NAV_MENU_TOOLTIP = 'nav-menu-tooltip',
  SALON_HOST_FOLLOW_TOOLTIP = 'salon-host-follow-tooltip',
  EVENT_HOST_FOLLOW_TOOLTIP = 'event-host-follow-tooltip',
  GATHERING_HOST_FOLLOW_TOOLTIP = 'gathering-host-follow-tooltip',
  GATHERING_OPEN_INVITATION_TOOLTIP = 'gathering-open-invitation-tooltip',
}

export enum ToolTipPointPosition {
  TOP_LEFT = '상단좌측',
  TOP_RIGHT = '상단우측',
  BOTTOM_LEFT = '하단좌측',
  BOTTOM_RIGHT = '하단우측',
  BOTTOM_MIDDLE = '하단중앙',
}

export enum TagType {
  SALON_CATEGORY = 'salonCategory', // 정기모임 카테고리
  DAY_OF_WEEK = 'dayOfWeek', // 요일 태그
  GATHERING_CATEGORY = 'gatheringCategory', // 소모임 카테고리
  REGION = 'region', // 모임 위치
  HOME_CURATION = 'homeCuration', // 홈 화면 Curation 주제
  KEYWORD = 'keyword', // 모임 설명 키워드
  SEASON = 'season', // 시즌 태그
  SALON_FILTER = 'salonFilter', // 정기 모임 버블 필터
  EVENT_FILTER = 'eventFilter', // 이벤트 버블 필터
}

export enum MeetupTypeNames {
  ALL = '전체',
  SALON = '정기 모임',
  EVENT = '이벤트',
  GATHERING = '소모임',
}

export enum FilterTypeNames {
  ORDER_BY_WISH_DESC = '담은순',
  ORDER_BY_SESSION_ASC = '시작임박순',
  ORDER_BY_IMMINENT = '마감임박순',
}

export enum WishedMeetupOrderType {
  SESSION_ASC = 'session_asc',
  WISH_DESC = 'bookmark_wish_desc',
}

export enum CategoryTagNames {
  ALL = '전체',
  LOVE = '사랑과 연애',
  MOVIE = '영화와 넷플릭스',
  CAREER = '일과 커리어',
  LIFESTYLE = '라이프스타일',
  PHILOSOPHY = '사유의 확장',
  MUSIC = '음악과 OST',
  RELATIONSHIP = '자아와 관계',
  WRITING = '글쓰기',
}

export enum GatheringCategoryTagNames {
  CONVERSATION = '모여서 대화',
  WRITING = '글쓰기',
  ACTIVITY = '액티비티 즐기기',
  CULTURE = '문화생활하기',
  FOOD = '맛집/카페 투어',
  DRINK = '분위기 있게 한잔',
  WORKSHOP = '워크숍',
  ETC = '기타',
}

export enum CurationTagNames {
  // OLD, DEPRECATED
  JAZZ = '재즈',
  WRITING = '글쓰기',
  BUNGAE = '번개가 잦은 모임',
  LOVESTORY = '사랑 이야기',
  DRINK = '와인과 술',
  BAKING = '베이킹',
  HUMAN = '인문학',
  ACTING = '연기/나탐구',
  NETFLIX = '넷플릭스',
  LEARNING = '배움/성장',
  EXHIBITION = '전시/산책',

  // NEW
  TASTE_NEW = '취향의확장',
  CULTURE_NEW = '문화예술',
  LOVE_NEW = '사랑',
  MOVIE_NEW = '영화',
  INTELLECTUAL_CONVERSION_NEW = '지적대화',
  EGO_NEW = '자아/또다른나',
  EAT_PLAY_LOVE_NEW = 'eatplaylove',
  CAREER_NEW = '일과성장',
  DRINK_NEW = '술디깅',

  // 마케팅용
  MARKETING_HIGH_RATED = '후기좋은',
  MARKETING_DRINK = '술이술술 영화모임',
  MARKETING_SF = 'SF의 세계로',
  MARKETING_COOK = '요리',
  MARKETING_TOGETHER = '같이 해요',
  MARKETING_IMMINENT = '마감 임박',
}

export enum SalonFilterTagNames {
  // 240306 new filter
  ALL = '전체',
  ENCORE = '첫 시작 추천 모임',
  LOVE = '사랑과 연애',
  MEETUP = '취향 친구 만나기',
  MOVIE = '영화와 시리즈',
  MUSIC = '음악과 공연',
  DRINK = '세상의 모든 술',
  FOOD = '요리와 음식',
  ART = '미술과 건축',
  JOB = '일과 커리어',
  MONEY = '금융과 부동산',
  EXPLORE = '지적 탐구',
  ACTING = '연기와 명상',
  DEEPTALK = '속 깊은 대화',
  OUTDOOR = '야외 활동',
  BOOK = '책과 글쓰기',
}

export enum EventFilterTagNames {
  ALL = '전체보기',
  LOVE = '운명적인 만남',
  EAT = '먹고 마시고 즐기고',
  MAKING = '뚝딱뚝딱 배우기',
  MEETUP = '와글와글 소셜링',
  OUTDOOR = '구석구석 도시 산책',
  DEEPTALK = '소근소근 깊은 대화',
  GANGNAM = '강남 지역 이벤트',
  GANGBUK = '강북 지역 이벤트',
}

export enum VisitRegionTagNames {
  ALL = '전체',
  GANGNAM = '강남 지역',
  GANGBUK = '강북 지역',
}

export enum StaticRegionTagNames {
  GANGNAM = '강남',
  SADANG = '사당/방배',
  JAMSIL = '잠실',
  HONGDAE = '홍대/신촌',
  HAPJEONG = '합정/상수',
  KUNDAE = '건대/성수',
  EULJIRO = '을지로',
  SEODAEMUN = '서대문',
  SINDANG = '신당',
}

export enum NetworksType {
  ALL = '전체',
  SALON_MEMBERS = '정기 모임 멤버들',
  EVENT_MEMBERS = '이벤트 멤버들',
  GATHERING_MEMBERS = '소모임 멤버들',
}

export const REGION_TAG_ASSETS_MAP = {
  [StaticRegionTagNames.GANGNAM]: {
    children: ['강남', '삼성', '선정릉', '압구정'],
  },
  [StaticRegionTagNames.SADANG]: {
    children: ['사당', '낙성대', '신림', '서울대입구'],
  },
  [StaticRegionTagNames.JAMSIL]: {
    children: ['잠실'],
  },
  [StaticRegionTagNames.HONGDAE]: {
    children: ['홍대', '신촌'],
  },
  [StaticRegionTagNames.HAPJEONG]: {
    children: ['합정', '영등포'],
  },
  [StaticRegionTagNames.KUNDAE]: {
    children: ['건대', '서울숲', '구의'],
  },
  [StaticRegionTagNames.EULJIRO]: {
    children: ['을지로', '시청'],
  },
  [StaticRegionTagNames.SEODAEMUN]: {
    children: ['서대문'],
  },
  [StaticRegionTagNames.SINDANG]: {
    children: ['신당'],
  },
};

export const bankNameArray = [
  { value: '신한은행' },
  { value: '국민은행' },
  { value: '우리은행' },
  { value: '하나은행' },
  { value: '기업은행' },
  { value: '하나은행' },
  { value: 'SC제일은행' },
  { value: '씨티은행' },
  { value: '농협은행' },
  { value: '카카오뱅크' },
  { value: '새마을금고' },
  { value: '신협' },
  { value: '전북은행' },
  { value: '경남은행' },
  { value: '대구은행' },
  { value: '광주은행' },
  { value: '우체국' },
  { value: '제주은행' },
  { value: '한국씨티은행' },
  { value: '수협은행' },
  { value: '케이뱅크' },
];

export const staticDayOfWeekTags = ['월', '화', '수', '목', '금', '토', '일'];

export const staticWeekDayTags = ['월', '화', '수', '목', '금'];

export const staticWeekendTags = ['토', '일'];

export const salonBanners = {
  default: '/salon_banner_default_new.webp',
  femaleTarget: '/salon_banner_2.webp',
  maleTarget: '/salon_banner_1.webp',
};

export const reviewList = [
  'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/ce1059c1-0e4c-4d49-b0f3-ee82cb478300/gamma=0',
  '/review2.webp',
  '/review3.webp',
  '/review4.webp',
  'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/28591b52-6f30-4f7e-0631-4725eeeea700/gamma=0',
  '/review6.webp',
];

export const categoryTagNames = [CategoryTagNames.ALL, CategoryTagNames.LOVE];

export interface CategoryKeywordColorItem {
  dim: string;
  light: string;
  regular: string;
}

export const EVENT_ASSET_MAP = {
  color: {
    dim: Colors.eventMain,
    light: Colors.eventMain,
    regular: Colors.eventMain,
  },
  icon: TagEvent,
};

export const GATHERING_ASSET_MAP = {
  color: {
    dim: Colors.eventMain,
    light: Colors.eventMain,
    regular: Colors.eventMain,
  },
  icon: TagEvent,
};

export const SALON_CATEGORY_TAG_ASSETS_MAP = {
  [CategoryTagNames.ALL]: {
    bgImage: '',
    color: {
      dim: Colors.gray11,
      light: Colors.gray11,
      regular: Colors.gray11,
    },
    icon: TagAll,
  },
  [CategoryTagNames.LOVE]: {
    bgImage: '/text_love.svg',
    color: {
      bg: '#FCF2F1',
      dim: '#AF2F25',
      light: '#F9A6A1',
      newRegular: Colors.newCategoryLove,
      regular: Colors.categoryLove,
    },
    icon: TagLove,
  },
  [CategoryTagNames.MOVIE]: {
    bgImage: '/text_movie.svg',
    color: {
      bg: '#E8EBF5',
      dim: '#3D4B7D',
      light: '#BECAF8',
      newRegular: Colors.newCategoryMovie,
      regular: Colors.categoryMovie,
    },
    icon: TagMovie,
  },
  [CategoryTagNames.CAREER]: {
    bgImage: '/text_work.svg',
    color: {
      bg: '#F2F4F6',
      dim: Colors.categoryCareer,
      light: '#BFC6CE',
      newRegular: Colors.newCategoryCareer,
      regular: Colors.categoryCareer,
    },
    icon: TagCareer,
  },
  [CategoryTagNames.LIFESTYLE]: {
    bgImage: '/text_life.svg',
    color: {
      bg: '#F5FAEF',
      dim: '#208436',
      light: '#A5DBB1',
      newRegular: Colors.newCategoryLifestyle,
      regular: Colors.categoryLifestyle,
    },
    icon: TagLifestyle,
  },
  [CategoryTagNames.PHILOSOPHY]: {
    bgImage: '/text_talk.svg',
    color: {
      bg: '#FFF9F0',
      dim: '#BD7710',
      light: '#FFD08C',
      newRegular: Colors.newCategoryPhilosophy,
      regular: Colors.categoryPhilosophy,
    },
    icon: TagPhilosophy,
  },
  [CategoryTagNames.MUSIC]: {
    bgImage: '/text_music.svg',
    color: {
      bg: '#F1F9F7',
      dim: '#326158',
      light: '#88D8CA',
      newRegular: Colors.newCategoryMusic,
      regular: Colors.categoryMusic,
    },
    icon: TagMusic,
  },
  [CategoryTagNames.RELATIONSHIP]: {
    bgImage: '/text_ego.svg',
    color: {
      bg: '#FBF6FF',
      dim: '#7F4BB5',
      light: '#DBC4F3',
      newRegular: Colors.newCategoryRelationship,
      regular: Colors.categoryRelationship,
    },
    icon: TagRelationship,
  },
  [CategoryTagNames.WRITING]: {
    bgImage: '/text_write.svg',
    color: {
      bg: '#EDF2FF',
      dim: '#3C67D4',
      light: '#ADC4FF',
      newRegular: Colors.newCategoryWriting,
      regular: Colors.categoryWriting,
    },
    icon: TagWriting,
  },
};

export const SALON_FILTER_TAG_ASSETS_MAP = {
  [SalonFilterTagNames.ALL]: {
    bubbleImg: '/salons/salon_bubble_all.png',
    text: '전체',
  },
  [SalonFilterTagNames.ENCORE]: {
    bubbleImg: '/salons/salon_bubble_encore.png',
    text: '첫 시작\n추천 모임',
  },
  [SalonFilterTagNames.LOVE]: {
    bubbleImg: '/salons/salon_bubble_love.png',
    text: '사랑과 연애',
  },
  [SalonFilterTagNames.MEETUP]: {
    bubbleImg: '/salons/salon_bubble_meetup.png',
    text: '취향 친구\n만나기',
  },
  [SalonFilterTagNames.MOVIE]: {
    bubbleImg: '/salons/salon_bubble_movie.png',
    text: '영화와 시리즈',
  },
  [SalonFilterTagNames.MUSIC]: {
    bubbleImg: '/salons/salon_bubble_music.png',
    text: '음악과 공연',
  },
  [SalonFilterTagNames.DRINK]: {
    bubbleImg: '/salons/salon_bubble_drink.png',
    text: '세상의\n모든 술',
  },
  [SalonFilterTagNames.FOOD]: {
    bubbleImg: '/salons/salon_bubble_food.png',
    text: '요리와 음식',
  },
  [SalonFilterTagNames.ART]: {
    bubbleImg: '/salons/salon_bubble_art.png',
    text: '미술과 건축',
  },
  [SalonFilterTagNames.JOB]: {
    bubbleImg: '/salons/salon_bubble_job.png',
    text: '일과 커리어',
  },
  [SalonFilterTagNames.MONEY]: {
    bubbleImg: '/salons/salon_bubble_money.png',
    text: '금융과\n부동산',
  },
  [SalonFilterTagNames.EXPLORE]: {
    bubbleImg: '/salons/salon_bubble_explore.png',
    text: '지적 탐구',
  },
  [SalonFilterTagNames.ACTING]: {
    bubbleImg: '/salons/salon_bubble_acting.png',
    text: '연기와 명상',
  },
  [SalonFilterTagNames.DEEPTALK]: {
    bubbleImg: '/salons/salon_bubble_deeptalk.png',
    text: '속 깊은 대화',
  },
  [SalonFilterTagNames.OUTDOOR]: {
    bubbleImg: '/salons/salon_bubble_outdoor.png',
    text: '야외 활동',
  },
  [SalonFilterTagNames.BOOK]: {
    bubbleImg: '/salons/salon_bubble_book.png',
    text: '책과 글쓰기',
  },
};

export const EVENT_FILTER_TAG_ASSETS_MAP = {
  [EventFilterTagNames.ALL]: {
    bubbleImg: '/events/event_bubble_all.png',
    text: '전체',
  },
  [EventFilterTagNames.LOVE]: {
    bubbleImg: '/events/event_bubble_love.png',
    text: '운명적인\n만남',
  },
  [EventFilterTagNames.EAT]: {
    bubbleImg: '/events/event_bubble_eat.png',
    text: '먹고 마시고\n즐기고',
  },
  [EventFilterTagNames.MAKING]: {
    bubbleImg: '/events/event_bubble_making.png',
    text: '뚝딱뚝딱\n배우기',
  },
  [EventFilterTagNames.MEETUP]: {
    bubbleImg: '/events/event_bubble_meetup.png',
    text: '와글와글\n소셜링',
  },
  [EventFilterTagNames.OUTDOOR]: {
    bubbleImg: '/events/event_bubble_outdoor.png',
    text: '구석구석\n도시 산책',
  },
  [EventFilterTagNames.DEEPTALK]: {
    bubbleImg: '/events/event_bubble_deeptalk.png',
    text: '소근소근\n깊은 대화',
  },
  [EventFilterTagNames.GANGNAM]: {
    bubbleImg: '/events/event_bubble_gangnam.png',
    text: '강남 지역\n이벤트',
  },
  [EventFilterTagNames.GANGBUK]: {
    bubbleImg: '/events/event_bubble_gangbuk.png',
    text: '강북 지역\n이벤트',
  },
};

export const EVENT_REGION_TAG_ASSETS_MAP = {
  [EventFilterTagNames.GANGNAM]: {
    children: [
      '낙성대',
      '삼성',
      '신림',
      '잠실',
      '사당',
      '선정릉',
      '서울대입구',
      '분당/서현',
      '강남',
      '영등포',
      '서초',
    ],
  },
  [EventFilterTagNames.GANGBUK]: {
    children: [
      '홍대',
      '을지로',
      '합정',
      '구의',
      '한남',
      '건대',
      '대학로',
      '안국',
      '신촌',
      '시청',
      '서대문',
      '성수',
      '종로',
      '신당',
      '중구',
    ],
  },
};

export const VISIT_REGION_TAG_ASSETS_MAP = {
  [VisitRegionTagNames.GANGNAM]: {
    children: [
      '낙성대',
      '삼성',
      '신림',
      '잠실',
      '사당',
      '선정릉',
      '서울대입구',
      '분당/서현',
      '강남',
      '영등포',
    ],
  },
  [VisitRegionTagNames.GANGBUK]: {
    children: [
      '홍대',
      '을지로',
      '합정',
      '구의',
      '한남',
      '건대',
      '대학로',
      '안국',
      '신촌',
      '시청',
      '서대문',
      '성수',
      '종로',
      '신당',
    ],
  },
};

export const GATHERING_CATEGORY_TAG_ASSETS_MAP = {
  [GatheringCategoryTagNames.CONVERSATION]: {
    emoji: '🗣',
  },
  [GatheringCategoryTagNames.WRITING]: {
    emoji: '✍️',
  },
  [GatheringCategoryTagNames.ACTIVITY]: {
    emoji: '🏃‍♂️',
  },
  [GatheringCategoryTagNames.CULTURE]: {
    emoji: '👀',
  },
  [GatheringCategoryTagNames.FOOD]: {
    emoji: '🍕',
  },
  [GatheringCategoryTagNames.DRINK]: {
    emoji: '🍷',
  },
  [GatheringCategoryTagNames.WORKSHOP]: {
    emoji: '💡',
  },
  [GatheringCategoryTagNames.ETC]: {
    emoji: '💬',
  },
};

export const CURATION_TAG_ASSETS_MAP = {
  [CurationTagNames.JAZZ]: {
    highlight: {
      author: "음악과 OST '거꾸로가는 유쾌한 재즈 교실' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/3a4723d2-0d99-4b4b-fe1e-99d4205d3b00/public',
      link: 'https://www.instagram.com/p/CenwOtzvuGY/',
      text: '"새로운 사람들을 만나 내 인생의 저변을 넓혀가는 일은 늘 즐거워요."',
    },
    items: [],
    name: CurationTagNames.JAZZ,
    title: '혹시 영화 음악🎷 좋아하세요? ',
  },
  [CurationTagNames.WRITING]: {
    highlight: {
      author: "글쓰기 '차곡차곡 에세이' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/4a6ab5d6-a8af-4b52-d264-ac77e58af600/public',
      link: 'https://blog.naver.com/99665/222391479140',
      text:
        '"표현이 서툰 저는 멤버들과 모임장님 덕분에 짧은 만남 속에서 저를 들여다 보는 방법을 배울 수 있었어요. 짧은 시간이었지만 정-말 밀도 깊게 많은 이야기를 나눌 수 있어서 좋았습니다."',
    },
    items: [],
    name: CurationTagNames.WRITING,
    title: '글을 쓰며✍️ 내 마음 되돌아 보기',
  },
  [CurationTagNames.BUNGAE]: {
    highlight: {
      author: "라이프스타일 '여행의 이유, 끝없이 모험하는 존재들' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/ac97d1c8-455c-4885-6c4f-38aacceb1c00/public',
      link: 'https://blog.naver.com/mickeygonebad/222132249683',
      text: '"여행자들과 넷플릭스를 봤더니 다시 대학생이 됐어요"',
    },
    items: [],
    name: CurationTagNames.BUNGAE,
    title: '금요일에 시간 어때요?\n번개⚡️가 많은 모임',
  },
  [CurationTagNames.LOVESTORY]: {
    highlight: {
      author: "사랑과 연애 '더 즐거운 섹스를 위한 성 상담소' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/22bd8ef1-cace-46fe-01e3-78dc42717500/public',
      link: 'https://blog.naver.com/wndus9308/222629217247',
      text:
        '"여기에서는 되게~ 안전하게 섹스에 대한 다양한 생각을 나눠요!  그런데 이런 이야기, 다른 곳에선 못하잖아요?"',
    },
    items: [],
    name: CurationTagNames.LOVESTORY,
    title: '"밤새도록 토크\n' + '사랑❤️이 우리를 구원하리"',
  },
  [CurationTagNames.DRINK]: {
    highlight: {
      author: "라이프스타일 '전통주 수작(酬酌)' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/6cac4303-c815-46e3-3a9e-1c2cd83b3300/public',

      link: 'https://blog.naver.com/ym_1451/222679294881',
      text:
        '"매번 색다른 술과 함께 멤버들이 가지고 온 음식으로 풍성한 모임이었어요. 다양한 술을 다양한 사람들과 함께 즐길 수 있어서 즐거웠습니다"',
    },
    items: [],
    name: CurationTagNames.DRINK,
    title: '와인 한 잔🍷 이젠 알고 술 마셔요',
  },
  [CurationTagNames.BAKING]: {
    highlight: {
      author: "라이프스타일 '영화가 부푸는 베이킹' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/a084b7f5-3709-4da6-6c1f-14187cd52e00/public',
      link: 'https://blog.naver.com/anadia1/222671789450',
      text:
        '"서로 다른 사람들이 영화와 베이킹 취향 하나로 하나가 된다니, 예쁘게 구워진 케이크를 반으로 갈라 생크림을 챱!"',
    },
    items: [],
    name: CurationTagNames.BAKING,
    title: '빵🍞 굽고 브런치 🍳먹는 사람들',
  },
  [CurationTagNames.HUMAN]: {
    highlight: {
      author: "영화와 넷플릭스 '토요 명화 극장' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/f11b12b8-88b0-47ef-ef6f-4b61ab2c8100/public',
      link: 'https://blog.naver.com/jhgqlrqod/222664615102',
      text:
        '"영화 얘길 하면서 각자 삶의 이야기들도 들을 수 있었고, 평소에 혼자 보기에는 어려웠던 영화를 함께 보고 생각을 나누는 시간이 뜻 깊었어요. 3시간이 부족해요"',
    },
    items: [],
    name: CurationTagNames.HUMAN,
    title: '사유의 확장🗯, 너무 재미있는 인문학',
  },
  [CurationTagNames.LEARNING]: {
    highlight: {
      author: "일과 커리어 '사이드 프로젝트 초급: 작심 삼 주 프로젝트' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/88befef2-4f32-4eb0-0adc-35a966a96800/public',
      link: 'https://blog.naver.com/yjm1057/222151351114',
      text:
        '"자기 자신을 더 채워가려고 노력하는 사람들과의 대화는 늘 즐거워요. 건강한 대화는 마음을 단단하게 해주고 행복 기운을 샘솟게 해줍니다."',
    },
    items: [],
    name: CurationTagNames.LEARNING,
    title: '같이 배우고👀 \n' + '성장하고💪🏻 싶은 사람 손',
  },
  [CurationTagNames.ACTING]: {
    highlight: {
      author: "'프로답게 말하는 법' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/672b424d-1dc8-4d08-96b8-137552855b00/public',
      link: 'https://blog.naver.com/jhanana/222427831891',
      text:
        '"말하기에 대한 두려움을 나누고, 다양한 분야에서 일하는 분들과의 공통점도 찾을 수 있었어요."',
    },
    items: [],
    name: CurationTagNames.ACTING,
    title: '또 다른 나를 발견! 내 부캐 찾기 🎭',
  },
  [CurationTagNames.EXHIBITION]: {
    highlight: {
      author: "사유의 확장 '어쩌다 도슨트' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/5a58fe14-7935-4720-efa7-d62858b22700/public',
      link: 'https://www.instagram.com/p/ChM4ebpJ8Qq/',
      text:
        '"알아야 한다는 강박을 놓고 나만의 방식으로 감상해도 된다는 것. 이건 미술뿐만 아니라 어디서든 써먹어볼 수 있지 않을까요."',
    },
    items: [],
    name: CurationTagNames.EXHIBITION,
    title: '경계 없는 문화 예술 살롱 🎨',
  },
  [CurationTagNames.NETFLIX]: {
    highlight: {
      author: "영화와 넷플릭스 '그 시절 우리가 사랑했던 홍콩영화' 참여 후기",
      image:
        'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/ed85dbd5-2372-4885-eee4-e51852786100/public',
      link: 'https://blog.naver.com/topm1104/222046654154',
      text:
        '"나 혼자 생각하고 느끼는 것 이상으로 멤버들의 이야기를 들을 수 있어서 좋은 시간들이었어요."',
    },
    items: [],
    name: CurationTagNames.NETFLIX,
    title: '넷플릭스 혼자 보는 사람들 모여라!🎬',
  },
  // NEW
  [CurationTagNames.CAREER_NEW]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.CAREER_NEW,
    title: '같이 배우고 성장하고 싶은 사람을 위해',
  },
  [CurationTagNames.DRINK_NEW]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.DRINK_NEW,
    title: '술잘알과 함께 하는 영화 한 잔 어때요?',
  },
  [CurationTagNames.CULTURE_NEW]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.CULTURE_NEW,
    title: '경계 없는 문화 예술 살롱',
  },
  [CurationTagNames.EGO_NEW]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.EGO_NEW,
    title: '나 들여다보기, 또다른 나를 발견하는 시간',
  },
  [CurationTagNames.LOVE_NEW]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.LOVE_NEW,
    title: '사랑이 우리를 구원하리, 밤새도록 토크',
  },
  [CurationTagNames.MOVIE_NEW]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.MOVIE_NEW,
    title: '접속, 영화 혼자 보는 사람들을 위한 모임',
  },
  [CurationTagNames.EAT_PLAY_LOVE_NEW]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.EAT_PLAY_LOVE_NEW,
    title: "'Eat, Play, Love'에 진심인 사람들",
  },
  [CurationTagNames.TASTE_NEW]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.TASTE_NEW,
    title: '조금 특별한 일상을 위한 취향의 확장',
  },
  [CurationTagNames.INTELLECTUAL_CONVERSION_NEW]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.INTELLECTUAL_CONVERSION_NEW,
    title: '지적 대화를 위한 넓고 얕은 인문학',
  },
  // 마케팅용
  [CurationTagNames.MARKETING_HIGH_RATED]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.MARKETING_HIGH_RATED,
    title: '지난 시즌 멤버들이 높은 평점을 남긴 모임 ❤️',
  },
  [CurationTagNames.MARKETING_DRINK]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.HUMAN,
    title: '술, 알고 마시면 더 맛있죠. 하지만 과음은 금물 🥃',
  },
  [CurationTagNames.MARKETING_SF]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.MARKETING_SF,
    title: "판타지 장르를 탐구해 봅니다. 아마 '듄'나 재미있을 걸요!",
  },
  [CurationTagNames.MARKETING_COOK]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.MARKETING_COOK,
    title: '영화 속에 나온 음식을 함께 만들어요 🥐',
  },
  [CurationTagNames.MARKETING_TOGETHER]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.MARKETING_TOGETHER,
    title: '액티비티가 있는 영화 모임. 조향부터 서울 탐방까지 ⚡️',
  },
  [CurationTagNames.MARKETING_IMMINENT]: {
    highlight: { author: '', image: '', link: '', text: '' },
    items: [],
    name: CurationTagNames.MARKETING_IMMINENT,
    title: '지금 가장 많은 관심을 받고 있는 모임이에요. 지금 등록하면 9월에 바로 시작 🥳',
  },
};

export const ratingInfo = [
  {
    text: '안좋았어요',
    value: 1,
  },
  {
    text: '아쉬웠어요',
    value: 2,
  },
  {
    text: '보통이에요',
    value: 3,
  },
  {
    text: '좋았어요',
    value: 4,
  },
  {
    text: '아주좋았어요',
    value: 5,
  },
];

export const roleTypeArrayWithMap = [
  {
    text: '모임지기',
    value: RoleType.ROLE_ASSISTANT,
  },
  {
    text: '번개돌이',
    value: RoleType.ROLE_GATHERING_LEADER,
  },
  {
    text: '포토그래퍼',
    value: RoleType.ROLE_PHOTOGRAPHER,
  },
  {
    text: '굿투머치토커',
    value: RoleType.ROLE_GOOD_TO_MUCH_TALKER,
  },
  {
    text: 'BGM 요정',
    value: RoleType.ROLE_BGM_FAIRY,
  },
  {
    text: '알람 요정',
    value: RoleType.ROLE_ALARM_FAIRY,
  },
  {
    text: '선의의 옹호자',
    value: RoleType.ROLE_ADVOCATE,
  },
];

export const nfygFAQ = [
  {
    answer: `넷플연가는 더 나은 모임을 만들어 갈 수 있도록 매 회차 익명 피드백 제도를 운영하고 있어요. 전달해 주신 피드백은 모임장님에게 전달되어 다수의 멤버들이 원하는 방향으로 모임을 개선해 가고 있습니다.
    \n\n다만, 내가 기대했던 모임의 분위기나 주제와 많이 다르거나 나와 맞지 않는다고 생각하신다면 고객센터로 문의주세요. 모임 변경 절차를 안내해 드립니다 :)`,
    image: '/faq-1.webp',
    question: 'Q. 모임이 저랑 맞지 않으면 어떡하죠?',
  },
  {
    answer: `모임 신청 전, 고객 센터를 통해 참여가 어려운 일정에 대해 증빙을 해주시면 기본적으로 지급되는 놀러가기권 외 1장 더 지급해 드립니다. (=총 2회 일정 대체가능)
    \n\n모임 시작 14일 전까지 취소 시 전액 환불이 가능하니 일정 걱정 없이 멤버십 신청을 고려해 보면 어떨까요? 이번 모임을 놓치면, 앵콜이 열리기까지 최소 3개월을 기다려야 할 수도 있어요!
    \n\n*신청 후에는 일정 불가로 인한 놀러가기권 지급이 불가합니다.`,
    image: '/faq-2.webp',
    question: 'Q. 참여하고 싶은데 불가한 일정이 있어서 신청이 망설여져요.',
  },
  {
    answer: `현재 넷플연가의 90% 이상의 정기 모임은 연기/취소 없이 정해진 시작일에 시작하고 있어요!
    \n\n하지만 간혹 시작 전 교환/환불/미달 등의 사유로 기간 내 좋은 모임 경험을 위한 최소 인원 모집이 이루어지지 않은 경우, 부득이하게 1회차 (3주) 연기 혹은 취소되는 경우가 발생할 수 있습니다.
    \n\n이 경우, 개별적으로 연락드리고 있으며 새로운 일정에 맞추어 참여 또는 환불을 편하게 선택하실 수 있어요 :)`,
    image: '/faq-3.webp',
    question: 'Q. 모집 미달로 모임이 취소될 수도 있나요?',
  },
];

export const donghaengFAQ = [
  {
    answer: `동행 시작 1시간 전, 게릴라 단톡방에 초대드립니다.

먼저 도착하신 분께서는 단톡방에 사진과 함께 도착 소식을 공유해주세요.

이후 프로필을 참고하여 서로를 소개하고, 사전에 제출한 ‘오늘의 질문’을 주제로 가볍게 이야기를 나누며 분위기를 풀어보세요!`,
    question: 'Q. 장소에 도착하면 서로를 어떻게 알아보나요?',
  },
  {
    answer: `가장 쉬운 정산 방법, 단 15초면 가능합니다.

대표로 한 분이 결제 후, [카카오페이 송금 QR]을 생성해 현장에서 바로 더치페이를 진행해주세요.

※ 추후 정산이 어려울 수 있으니, 반드시 현장에서 정산을 완료해주세요!`,
    question: 'Q. 당일 현장 비용은 어떻게 계산하나요?',
  },
  {
    answer: `동행 참여 시, 10분 웰컴 숏터뷰는 필수입니다.

넷플연가는 모임 시작 전, 함께하는 멤버들이 어떤 분들인지 서로 알 수 있도록 모든 멤버를 대상으로 1:1 인터뷰를 진행하고 있습니다.

이 과정을 통해 각 멤버의 매력을 담은 멋진 프로필을 완성하고, 보다 안전하고 즐거운 만남의 경험을 제공합니다.`,
    question: 'Q. 동행 참여시 인터뷰가 꼭 필요한가요?',
  },
  {
    answer: `정기 모임 참여 종료 후에는 평생 이용 가능한 알럼나이 멤버십이 제공됩니다.

멤버십을 통해 한 달에 한 번 동행 및 소모임에 참여할 수 있는 혜택을 누리실 수 있습니다.

정기모임 이후에도 90일이 지나도 낯설지만 좋은 사람들과의 연결을 이어가 보세요!

*알럼나이는 ‘졸업자’와 ‘동문’을 의미합니다.`,
    question: 'Q. 정기 모임이 끝나도 참여할 수 있나요?',
  },
];

export const gatheringFAQ = [
  {
    answer: `소모임 참여 시, 10분 웰컴 숏터뷰는 필수입니다.

넷플연가는 모임 시작 전, 함께하는 멤버들이 어떤 분들인지 서로 알 수 있도록 모든 멤버를 대상으로 1:1 인터뷰를 진행하고 있습니다.

이 과정을 통해 각 멤버의 매력을 담은 멋진 프로필을 완성하고, 보다 안전하고 즐거운 만남의 경험을 제공합니다.`,
    question: 'Q. 소모임 참여시 인터뷰가 꼭 필요한가요?',
  },
  {
    answer: `정기 모임 참여 종료 후에는 평생 이용 가능한 알럼나이 멤버십이 제공됩니다.

멤버십을 통해 한 달에 한 번 동행 및 소모임에 참여할 수 있는 혜택을 누리실 수 있습니다.

정기모임 이후에도 90일이 지나도 낯설지만 좋은 사람들과의 연결을 이어가 보세요!

*알럼나이는 ‘졸업자’와 ‘동문’을 의미합니다.`,
    question: 'Q. 정기 모임이 끝나도 참여할 수 있나요?',
  },
];

export enum TentCategoryNames {
  JOB = '일/커리어',
  MOVIE = '영화/드라마',
  BOOK = '책/인문학',
  DRINK = '술/음식',
  CONVERSATION = '대화/수다',
  LOVE = '사랑/연애',
  LIFESTYLE = '라이프스타일',
  ART = '미술/건축',
  MUSIC = '음악',
  MONEY = '재테크/부동산',
}

export const SALON_BUBBLE_FILTER_TENT_CATEGORY_MAP = {
  [SalonFilterTagNames.JOB]: {
    children: [TentCategoryNames.JOB],
  },
  [SalonFilterTagNames.MOVIE]: {
    children: [TentCategoryNames.MOVIE],
  },
  [SalonFilterTagNames.DRINK]: {
    children: [TentCategoryNames.DRINK],
  },
  [SalonFilterTagNames.MONEY]: {
    children: [TentCategoryNames.JOB],
  },
  [SalonFilterTagNames.EXPLORE]: {
    children: [TentCategoryNames.BOOK, TentCategoryNames.JOB],
  },
  [SalonFilterTagNames.ACTING]: {
    children: [TentCategoryNames.LIFESTYLE],
  },
  [SalonFilterTagNames.MUSIC]: {
    children: [TentCategoryNames.MUSIC],
  },
  [SalonFilterTagNames.BOOK]: {
    children: [TentCategoryNames.BOOK],
  },
  [SalonFilterTagNames.FOOD]: {
    children: [TentCategoryNames.DRINK],
  },
  [SalonFilterTagNames.OUTDOOR]: {
    children: [TentCategoryNames.LIFESTYLE],
  },
  [SalonFilterTagNames.MEETUP]: {
    children: [TentCategoryNames.ART, TentCategoryNames.MUSIC, TentCategoryNames.MOVIE],
  },
  [SalonFilterTagNames.ART]: {
    children: [TentCategoryNames.ART],
  },
  [SalonFilterTagNames.DEEPTALK]: {
    children: [TentCategoryNames.CONVERSATION, TentCategoryNames.BOOK],
  },
  [SalonFilterTagNames.LOVE]: {
    children: [TentCategoryNames.LOVE],
  },
};

export const EVENT_BUBBLE_FILTER_TENT_CATEGORY_MAP = {
  [EventFilterTagNames.LOVE]: {
    children: [TentCategoryNames.LOVE],
  },
  [EventFilterTagNames.EAT]: {
    children: [TentCategoryNames.DRINK],
  },
  [EventFilterTagNames.DEEPTALK]: {
    children: [
      TentCategoryNames.MOVIE,
      TentCategoryNames.CONVERSATION,
      TentCategoryNames.MUSIC,
      TentCategoryNames.BOOK,
    ],
  },
  [EventFilterTagNames.MAKING]: {
    children: [TentCategoryNames.ART, TentCategoryNames.MONEY, TentCategoryNames.JOB],
  },
  [EventFilterTagNames.MEETUP]: {
    children: [TentCategoryNames.DRINK, TentCategoryNames.LOVE],
  },
  [EventFilterTagNames.OUTDOOR]: {
    children: [TentCategoryNames.ART, TentCategoryNames.LIFESTYLE],
  },
};

export enum CancelReason {
  REASON_SCHEDULE = '일정이 어려워져서',
  REASON_OTHER_MEETUP = '다른 모임에 신청하고 싶어서',
  REASON_WAITING_PERIOD = '첫 모임까지 대기 시간이 길어서',
  REASON_COST = '비용이 부담 되어서',
  REASON_CHANGE_MIND = '단순 변심',
  REASON_OTHER = '그 외 사유',
}

export const registerCancelReasons = [
  CancelReason.REASON_SCHEDULE,
  CancelReason.REASON_OTHER_MEETUP,
  CancelReason.REASON_WAITING_PERIOD,
  CancelReason.REASON_COST,
  CancelReason.REASON_CHANGE_MIND,
  CancelReason.REASON_OTHER,
];

export const visitCancelReasons = [
  CancelReason.REASON_SCHEDULE,
  CancelReason.REASON_OTHER_MEETUP,
  CancelReason.REASON_CHANGE_MIND,
  CancelReason.REASON_OTHER,
];

// FIXME: 프론트에서 사용하는 이벤트 탭 상태 확인을 위한 constant enum, 추후 백엔드와 일치시켜야 할 가능성이 있음
export enum TabStatus {
  BEFORE_OPENING = 'before_opening',
  AVAILABLE = 'available',
  WAITLIST = 'waitlist',
}

export enum GatheringType {
  ALL = 'all',
  HOST_LED = 'hostLed',
  DONGHAENG = 'donghaeng',
}

export const EVENT_BUBBLE_FILTER_PROMOTION_BANNER_MAP = {
  [EventFilterTagNames.LOVE]: {
    link: 'https://bit.ly/488l8do',
    src: '/events/event_promo_love.png',
  },
  [EventFilterTagNames.EAT]: {
    link: 'https://bit.ly/3OuiFmJ',
    src: '/events/event_promo_eat.png',
  },
  [EventFilterTagNames.DEEPTALK]: {
    link: 'https://bit.ly/48WqLN4',
    src: '/events/event_promo_deeptalk.png',
  },
  [EventFilterTagNames.MAKING]: {
    link: 'https://bit.ly/3UsyP3B',
    src: '/events/event_promo_making.png',
  },
  [EventFilterTagNames.MEETUP]: {
    link: 'https://bit.ly/497GA3O',
    src: '/events/event_promo_meetup.png',
  },
  [EventFilterTagNames.OUTDOOR]: {
    link: 'https://bit.ly/42rCBwg',
    src: '/events/event_promo_outdoor.png',
  },
};

export const NFYG_WHAT_TIME_WELCOME_INTERVIEW_URL = 'https://whattime.co.kr/nfyg-welcome/welcome'
export const NFYG_WHAT_TIME_CHANNEL_CHAT_URL = 'https://pf.kakao.com/_eFVFs/chat'
export const NFYG_CHANNEL_TALK_URL = 'https://nfyg.channel.io'
export const NFYG_MEMBER_PROFILE_LIST_URL = 'https://story.nfyg.co/members';

export const NFYG_NEW_USER_LIMIT_FOR_WELCOME_INTERVIEW = 40;
export const NFYG_ALUMNI_MEMBERSHIP_EXPIRATION_DATE = '2099-12-31 00:00:00';
export const DONGHAENG_GATHERING_HOST_ID = '39628';
